/* global $ jQuery Swiper ajax_url */

$ = jQuery;

var $log = function (msg) {
        if (App.debug) {
            if (arguments.length > 1) {
                var to_console = [];
                for (i = 1; i < arguments.length; i++) {
                    to_console[i - 1] = arguments[i][0];
                }
                console.log(msg, to_console);
            } else {
                console.log(msg);
            }
        }
    },
    App = {
        debug: false,
        vars: {},
        init: function () {
            this.vars.body = $('body');

            this.scripts();
            this.toast_system();
            this.sliders();
        },
        loaded: function () {},
        scripts: function () {
            var $win = $(window);

            if (App.is_touch_device()) {
                App.vars.body.addClass('touch');
            } else {
                App.vars.body.addClass('no-touch');
            }

            // To top
            $('.back-top').on('click', function () {
                $('html,body').animate(
                    {
                        scrollTop: 0
                    },
                    550
                );
            });

            // $('[data-toggle="tooltip"]').tooltip();

            if (typeof Waypoint !== 'undefined') {
                var continuousElements = $('.image-text-section .content-wrap, .faq-section .row, .tabbed-section .row, .top-lists .col-12, .home-news .news-list');
                var introElements = $('.info-row-section, .intro-section h1, .intro-section .btn-wrapper');

                var alreadyScrolled = false,
                    whiteInView = false,
                    $whiteSections = $('.section.white'),
                    $grayLoop = $('.video-bg-wrapper.loop-video-gray'),
                    $blueLoop = $('.video-bg-wrapper.loop-video-blue'),
                    $introVideo = $('.video-bg-wrapper.intro-video'),
                    $transitionVideo = $('.video-bg-wrapper.transition-video'),
                    $pageHeader = $('.page-header'),
                    currentSection,
                    moveDown,
                    moveUp;

                setTimeout(function () {
                    for (var i = 0; i < continuousElements.length; i++) {
                        var waypoint = new Waypoint({
                            element: continuousElements[i],
                            handler: function (direction) {
                                $(this.element).addClass('animated fadeInUp');
                            },
                            offset: '70%'
                        });
                    }
                }, 700);

                if (App.vars.body.hasClass('home')) {
                    setTimeout(function () {
                        var waypoint = new Waypoint({
                            element: $pageHeader,
                            handler: function (direction) {
                                $(this.element).addClass('animated fadeInUp');
                            },
                            offset: '0'
                        });
                    }, 1000);

                    setTimeout(function () {
                        introElements.each(function (i, e) {
                            var $this = $(this);

                            setTimeout(function () {
                                var waypoint = new Waypoint({
                                    element: $this,
                                    handler: function (direction) {
                                        $(this.element).addClass('animated fadeInUp');
                                    },
                                    offset: '90%'
                                });
                            }, i * 250);
                        });
                    }, 1500);
                } else {
                    introElements.each(function (i, e) {
                        var $this = $(this);

                        setTimeout(function () {
                            var waypoint = new Waypoint({
                                element: $this,
                                handler: function (direction) {
                                    $(this.element).addClass('animated fadeInUp');
                                },
                                offset: '70%'
                            });
                        }, i * 250);
                    });
                }
            }

            App.float_label('body');

            // Content link
            $('.content-link').click(function (e) {
                if ($(this).attr('data-slug').length && typeof $(this).data('slug') !== 'undefined') {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $('html,body').animate(
                        {
                            scrollTop: $('#' + id).offset().top - 50
                        },
                        350
                    );
                }
            });

            // Login link

            var admin_login_trigger = $('.admin-login-trigger a'),
                login_overlay = $('.login-overlay'),
                login_flow_overlay = $('.login-flow-overlay'),
                login_flow_trigger = $(".login-overlay .login-flow-link,  a[href='#admin-login-trigger']");

            if (app_logoff && app_logoff.label) {
                admin_login_trigger.html(app_logoff.label).attr('href', app_logoff.href);
            } else {
                admin_login_trigger.click(function (e) {
                    e.preventDefault();
                    $('.login-overlay,body').addClass('login-opened');
                });
            }

            $('.overlay').click(function (e) {
                e.preventDefault();
                if (App.vars.body.hasClass('login-opened')) {
                    $('.login-overlay,body').removeClass('login-opened');
                }
            });

            $('.login-overlay > .close').click(function (e) {
                e.preventDefault();
                $('.login-overlay,body').removeClass('login-opened');
            });

            login_flow_trigger.click(function (e) {
                e.preventDefault();
                $('.login-overlay, body').removeClass('login-opened');
                $('body').addClass('login-flow-opened');
                login_flow_overlay.addClass('login-flow-opened');
            });

            $('.close', login_flow_overlay).click(function (e) {
                e.preventDefault();
                $('.login-flow-overlay,body').removeClass('login-flow-opened');
            });

            var login_id = $('#login-id-auth'),
                login_mid_wrap = $('#mobileIdLoginCollapse'),
                login_mid_step_1 = $('#login-mid-step-1', login_mid_wrap),
                login_mid_step_2 = $('#login-mid-step-2', login_mid_wrap),
                login_mid_phone = $('#login-mid-phone', login_mid_step_1),
                login_mid_idcode = $('#login-mid-idcode', login_mid_step_1),
                login_mid_authenicate = $('#login-mid-auth', login_mid_step_1),
                login_smid_wrap = $('#smartIdLoginCollapse'),
                login_smid_step_1 = $('#login-smid-step-1', login_smid_wrap),
                login_smid_step_2 = $('#login-smid-step-2', login_smid_wrap),
                login_smid_idcode = $('#login-smid-idcode', login_smid_step_1),
                login_smid_authenicate = $('#login-smid-auth', login_smid_step_1),
                login_redirect = function () {
                    var redirect = document.location.href;

                    if (document.login_redirect && document.login_redirect.length) {
                        redirect = document.login_redirect;
                    }

                    document.location.href = redirect;
                },
                login_register = function (signon_data) {
                    $('.close', login_flow_overlay).trigger('click');

                    var register_modal = $('#login-register'),
                        error = $('.error', register_modal),
                        user_email = $('#user_email', register_modal),
                        submit = $('button', register_modal);

                    submit.on('click', function (event) {
                        event.preventDefault();

                        error.addClass('d-none');

                        if (user_email.val().length) {
                            signon_data.email = user_email.val();

                            $.post(
                                ajax_url,
                                signon_data,
                                function (response) {
                                    if (response.success) {
                                        if (response.data && response.data.redirect) {
                                            document.login_redirect = response.data.redirect;
                                        }

                                        login_redirect();
                                    } else {
                                        error.text(response.error).removeClass('d-none');
                                    }
                                },
                                'json'
                            );
                        }
                    });

                    user_email.on('keyup', function (event) {
                        event.preventDefault();
                        if (event.keyCode == 13) {
                            submit.trigger('click');
                        }
                    });

                    register_modal.show().removeClass('fade');
                };

            login_id.on('click', function (event) {
                event.preventDefault();

                try {
                    $.ajax({
                        url: login_id.data('endpoint'),
                        dataType: 'jsonp'
                    })
                        .fail(function () {
                            alert('Viga ID kaardiga audentimises! =)');
                        })
                        .done(function (response) {
                            var signon_data = {
                                action: 'api',
                                method: 'signon',
                                data: response.data
                            };

                            if (document.invitaion_key) {
                                signon_data.invitation = document.invitaion_key;
                            }

                            $.post(
                                ajax_url,
                                signon_data,
                                function (response) {
                                    if (response.success) {
                                        login_redirect();
                                    } else {
                                        login_register(signon_data);
                                    }
                                },
                                'json'
                            );
                        });
                } catch (e) {
                    alert('Viga ID kaardiga audentimises! =)');
                }
            });

            login_mid_phone.on('keyup', function (event) {
                event.preventDefault();
                if (event.keyCode == 13) {
                    login_mid_authenicate.trigger('click');
                }
            });
            login_mid_authenicate.on('click', function (event) {
                event.preventDefault();

                var phone = login_mid_phone.val(),
                    idcode = login_mid_idcode.val(),
                    error = $('.error', login_mid_step_1);

                if (phone.length < 4) {
                    return;
                }

                error.addClass('d-none').text('');
                login_mid_step_1.addClass('d-none');
                login_mid_step_2.removeClass('d-none');

                $.ajax({
                    method: 'post',
                    url: login_mid_wrap.data('endpoint'),
                    dataType: 'json',
                    data: {
                        telno: phone,
                        idcode: idcode
                    },
                    success: function (data) {
                        if (data.stage == 'error') {
                            login_mid_step_2.addClass('d-none');
                            login_mid_step_1.removeClass('d-none');
                            error.text(data.error).removeClass('d-none');

                            return;
                        } else if (data.stage == 'progress') {
                            login_mid_step_2.text(login_mid_step_2.data('text') + ' ' + data.challenge);

                            var wait = null;

                            wait = window.setInterval(function () {
                                $.post(
                                    login_mid_wrap.data('endpoint'),
                                    {
                                        progress: 'wait'
                                    },
                                    function (data_check) {
                                        if (data_check.stage == 'error') {
                                            clearInterval(wait);
                                            login_mid_step_2.addClass('d-none');
                                            login_mid_step_1.removeClass('d-none');

                                            error.text(data_check.error).removeClass('d-none');
                                        } else if (data_check.stage == 'authenticated') {
                                            clearInterval(wait);

                                            var signon_data = {
                                                action: 'api',
                                                method: 'signon',
                                                data: data_check.data
                                            };

                                            if (document.invitaion_key) {
                                                signon_data.invitation = document.invitaion_key;
                                            }

                                            $.post(
                                                ajax_url,
                                                signon_data,
                                                function (response) {
                                                    if (response.success) {
                                                        login_redirect();
                                                    } else {
                                                        login_register(signon_data);
                                                    }
                                                },
                                                'json'
                                            );
                                        }
                                    },
                                    'json'
                                );
                            }, 5000);

                            return;
                        }
                    }
                });
            });

            login_smid_idcode.on('keyup', function (event) {
                event.preventDefault();
                if (event.keyCode == 13) {
                    login_smid_authenicate.trigger('click');
                }
            });
            login_smid_authenicate.on('click', function (event) {
                event.preventDefault();

                var idcode = login_smid_idcode.val(),
                    error = $('.error', login_smid_step_1);

                if (idcode.length < 10) {
                    return;
                }

                error.addClass('d-none').text('');
                login_smid_step_1.addClass('d-none');
                login_smid_step_2.removeClass('d-none');

                $.ajax({
                    method: 'post',
                    url: login_smid_wrap.data('endpoint'),
                    dataType: 'json',
                    data: {
                        idcode: idcode
                    },
                    success: function (data) {
                        if (data.stage == 'error') {
                            login_smid_step_2.addClass('d-none');
                            login_smid_step_1.removeClass('d-none');
                            error.text(data.error).removeClass('d-none');

                            return;
                        } else if (data.stage == 'progress') {
                            login_smid_step_2.text(login_smid_step_2.data('text') + ' ' + data.challenge);

                            var wait = null;

                            wait = window.setInterval(function () {
                                $.post(
                                    login_smid_wrap.data('endpoint'),
                                    {
                                        progress: 'wait'
                                    },
                                    function (data_check) {
                                        if (data_check.stage == 'error') {
                                            clearInterval(wait);
                                            login_smid_step_2.addClass('d-none');
                                            login_smid_step_1.removeClass('d-none');

                                            error.text(data_check.error).removeClass('d-none');
                                        } else if (data_check.stage == 'authenticated') {
                                            clearInterval(wait);

                                            var signon_data = {
                                                action: 'api',
                                                method: 'signon',
                                                data: data_check.data
                                            };

                                            if (document.invitaion_key) {
                                                signon_data.invitation = document.invitaion_key;
                                            }

                                            $.post(
                                                ajax_url,
                                                signon_data,
                                                function (response) {
                                                    if (response.success) {
                                                        login_redirect();
                                                    } else {
                                                        login_register(signon_data);
                                                    }
                                                },
                                                'json'
                                            );
                                        }
                                    },
                                    'json'
                                );
                            }, 5000);

                            return;
                        }
                    }
                });
            });

            $win.on('keyup', function (event) {
                if ($('.login-overlay').hasClass('login-opened')) {
                    switch (event.keyCode) {
                        case 27: //esc
                            $('.login-overlay').removeClass('login-opened');
                            break;
                    }
                }
            });

            // ###
            var $scrollIndicator = $('.scroll-down-indicator'),
                $searchTrigger = $('.search-trigger'),
                $searchClose = $('.search-close'),
                $eventsCal = $('#eventsComingUp'),
                $slides = $('.slides-section'),
                $pageHeader = $('.page-header');

            $searchTrigger.click(function (e) {
                e.preventDefault();
                $pageHeader.addClass('search-opened');
                $(this).parent().addClass('search-opened');
                $(this).parent().find('form input[type=text]').focus();
            });

            $searchClose.click(function (e) {
                e.preventDefault();
                $pageHeader.removeClass('search-opened');
                $(this).parent().parent().removeClass('search-opened');
            });

            // Slides in view

            if (typeof Waypoint !== 'undefined') {
                var inview = new Waypoint.Inview({
                    element: $('.slides-container', $slides),
                    offset: '50%',
                    enter: function (direction) {
                        // $log('Enter triggered with direction ' + direction);
                        $('.slides-container', $slides)
                            .removeClass('up down')
                            .addClass('entered ' + direction);
                    },
                    entered: function (direction) {
                        // $log('Entered triggered with direction ' + direction)
                        $('.slides-container', $slides)
                            .removeClass('entered in-view up down')
                            .addClass('exiting ' + direction);
                    },
                    exit: function (direction) {
                        // $log('Exit triggered with direction ' + direction)
                        $('.slides-container', $slides).removeClass('entered exiting up down').addClass('in-view');
                    },
                    exited: function (direction) {
                        // $log('Exited triggered with direction ' + direction);
                        $('.slides-container', $slides).removeClass('in-view entered exiting up down');
                    }
                });

                $('.slider-contents .swiper-slide', $slides).each(function (i, element) {
                    var self = $(element),
                        menu_elements = $('.slider-tabs .swiper-slide', $slides);

                    new Waypoint({
                        element: element,
                        handler: function () {
                            menu_elements
                                .removeClass('is-selected')
                                .filter('[data-slide="' + self.data('slide') + '"]')
                                .addClass('is-selected');
                        },
                        offset: '40%'
                    });
                });
            }

            $('.slider-tabs .swiper-slide', $slides).click(function (e) {
                $('.slider-tabs .swiper-slide', $slides).removeClass('is-selected');
                if ($(this).attr('data-slug').length && typeof $(this).data('slug') !== 'undefined') {
                    e.preventDefault();
                    var id = $(this).attr('data-slug');
                    $(this).addClass('is-selected');
                    $('html,body').animate(
                        {
                            scrollTop: $('#' + id).offset().top - $win.height() / 4
                        },
                        550
                    );
                }
            });

            $win.on('scroll', function () {
                if ($(this).scrollTop() >= 20) {
                    App.vars.body.addClass('page-scrolled');
                } else {
                    App.vars.body.removeClass('page-scrolled');
                }
            });
        },
        animation_frame: function () {},
        sliders: function () {
            var $win = $(window);

            var mob_coming_events_slider = $('.mobile-coming-events-slider-wrapper .swiper-container');

            if (mob_coming_events_slider.length) {
                var mob_coming_events_slider = new Swiper(mob_coming_events_slider, {
                    loop: false,
                    effect: 'slide',
                    //centeredSlides: true,
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    paginationHide: true,
                    paginationType: 'bullets',
                    slidesPerView: 1,
                    spaceBetween: 0,
                    speed: 700,
                    autoHeight: true,
                    grabCursor: false,
                    //autoplay: 6000,
                    navigation: {
                        nextEl: '.mobile-coming-events-slider-wrapper .swiper-button-next',
                        prevEl: '.mobile-coming-events-slider-wrapper .swiper-button-prev'
                    },
                    breakpoints: {
                        // when window width is <= 480px
                        480: {
                            slidesPerView: 1
                        }
                    }
                });
            }

            var coming_events_slider = $('.coming-events-slider-wrapper .swiper-container');

            if (coming_events_slider.length) {
                var coming_events_swiper_slider = new Swiper(coming_events_slider, {
                    loop: false,
                    effect: 'slide',
                    //centeredSlides: true,
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    paginationHide: true,
                    paginationType: 'bullets',
                    slidesPerView: 3,
                    spaceBetween: 0,
                    speed: 700,
                    grabCursor: false,
                    //autoplay: 6000,
                    navigation: {
                        nextEl: '.coming-events-slider-wrapper .swiper-button-next',
                        prevEl: '.coming-events-slider-wrapper .swiper-button-prev'
                    },
                    breakpoints: {
                        // when window width is <= 480px
                        480: {
                            slidesPerView: 1
                        },
                        768: {
                            slidesPerView: 1
                        },
                        1024: {
                            slidesPerView: 2
                        }
                    }
                });
            }

            var slides_contents_slider = $('.slider-contents.swiper-container');

            var slides_tabs_slider = $('.slider-tabs.swiper-container');

            if (slides_contents_slider.length && slides_tabs_slider.length) {
                if ($win.width() > 1023) {
                    var slides_tabs_swiper_slider,
                        slides_contents_swiper_slider,
                        slides_parent_bottom,
                        slides_parent_position_over,
                        slides_parent = slides_contents_slider.parents('.slides-section:first'),
                        scroll_lock_position = false,
                        slides_ended = false,
                        positions_calculate = function () {
                            //console.log("Recalculate positions")
                            slides_parent_bottom = parseInt(slides_parent.offset().top + slides_parent.outerHeight(true));
                            slides_parent_position_over = parseInt(slides_parent_bottom - window.innerHeight);
                        },
                        scroll_lock = function (event) {
                            if (scroll_lock_position) {
                                if (event.deltaY > 0) {
                                    //console.log(event.deltaY);
                                    event.preventDefault();
                                }

                                if (window.scrollY != scroll_lock_position) {
                                    $win.scrollTop(scroll_lock_position);
                                }
                            }

                            // event.stopImmediatePropagation();
                            // event.stopPropagation();
                        };

                    $(window).on('load resize', function () {
                        positions_calculate();
                    });

                    slides_parent.parent().get(0).addEventListener('wheel', scroll_lock, {
                        passive: false,
                        capture: true
                    });
                    slides_parent.parent().get(0).addEventListener('scroll', scroll_lock, {
                        passive: false,
                        capture: true
                    });

                    App.animation_frame = function () {
                        var window_scroll_bottom = parseInt(window.scrollY + window.innerHeight) + 1;

                        if (window_scroll_bottom >= slides_parent_bottom) {
                            //$log(window_scroll_bottom);

                            if (slides_ended) {
                                //$log("Unlock scrolling");
                                scroll_lock_position = false;
                                slides_contents_swiper_slider.mousewheel.disable();
                            }

                            if (!scroll_lock_position && !slides_ended) {
                                //$log("Lock scrolling");
                                scroll_lock_position = parseInt(slides_parent_position_over);
                                $win.scrollTop(scroll_lock_position);
                                slides_contents_swiper_slider.mousewheel.enable();
                            }
                        }
                    };

                    if (!slides_contents_swiper_slider) {
                        $('.swiper-slide', slides_tabs_slider).first().addClass('is-selected');

                        slides_tabs_swiper_slider = new Swiper(slides_tabs_slider, {
                            direction: 'vertical',
                            slidesPerView: 'auto',
                            centeredSlides: false,
                            freeMode: false,
                            simulateTouch: true,
                            preventInteractionOnTransition: true,
                            slideToClickedSlide: true,
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                            pagination: {
                                el: '.swiper-pagination',
                                type: 'bullets'
                            },
                            on: {
                                click: function () {
                                    var clicked = slides_tabs_swiper_slider.clickedIndex;

                                    slides_tabs_swiper_slider.activeIndex = clicked; //don't need this
                                    slides_tabs_swiper_slider.updateSlidesClasses(); //don't need this
                                    $(slides_tabs_swiper_slider.slides).removeClass('is-selected');
                                    $(slides_tabs_swiper_slider.clickedSlide).addClass('is-selected');
                                    slides_contents_swiper_slider.slideTo(clicked, 500, false);
                                }
                            }
                        });

                        slides_contents_swiper_slider = new Swiper(slides_contents_slider, {
                            direction: 'vertical',
                            slidesPerView: 1,
                            spaceBetween: 30,
                            preventInteractionOnTransition: true,
                            keyboard: {
                                enabled: false,
                                onlyInViewport: false
                            },
                            mousewheel: {
                                eventsTarged: slides_contents_slider.parents('.slides-section')
                            },
                            speed: 700,
                            thumbs: {
                                //swiper: slides_tabs_swiper_slider,
                            },
                            on: {
                                imagesReady: positions_calculate,
                                reachBeginning: function () {
                                    slides_contents_swiper_slider.mousewheel.disable();
                                    slides_ended = true;
                                },
                                reachEnd: function () {
                                    // setTimeout(function() {
                                    slides_contents_swiper_slider.mousewheel.disable();
                                    slides_ended = true;
                                    // }, 500);
                                },
                                slideChangeTransitionStart: function () {
                                    var activeIndex = slides_contents_swiper_slider.activeIndex;

                                    $(slides_tabs_swiper_slider.slides).removeClass('is-selected');
                                    $(slides_tabs_swiper_slider.slides).eq(activeIndex).addClass('is-selected');
                                    slides_tabs_swiper_slider.slideTo(activeIndex, 500, false);
                                }
                            }
                        });

                        slides_contents_swiper_slider.mousewheel.disable();
                    } else {
                        //slides_contents_swiper_slider.destroy(true, true);
                    }
                }
            }

            var home_news_slider = $('.home-news-slider-wrapper .swiper-container');

            if (home_news_slider.length) {
                var home_news_swiper_slider = new Swiper(home_news_slider, {
                    loop: false,
                    loopedSlides: '1',
                    effect: 'slide',
                    centeredSlides: true,
                    paginationClickable: true,
                    paginationHide: false,
                    slidesPerView: 'auto',
                    speed: 700,
                    grabCursor: false,
                    autoHeight: false,
                    spaceBetween: 0,
                    //autoplay: 6000,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets'
                    },
                    navigation: {
                        nextEl: '.home-news-slider-wrapper .swiper-button-next',
                        prevEl: '.home-news-slider-wrapper .swiper-button-prev'
                    },
                    breakpoints: {
                        // when window width is <= 1024px
                        760: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        }
                    }
                });
            }
        },

        toast_system: function () {
            $(document).ajaxSuccess(function (event, jqXHR, ajaxOptions, response) {
                if (typeof response !== 'undefined') {
                    if (typeof response.data !== 'undefined' && typeof response.data.toast !== 'undefined') {
                        App.toast_show(response.data.toast);
                    }
                }
            });
        },

        toast_show: function (msg) {
            var toast = $('#toast-alert'),
                toast_body = $('.toast-body', toast);

            toast_body.empty().html(msg);
            toast.toast('show');
        },

        float_label: function (target) {
            $('.gfield input, .gfield textarea, .input-field input, .input-field textarea', target).each(function () {
                var $this = $(this);
                var text_value = $(this).val();

                // on focus add class "active" to label
                $this.focus(function () {
                    $this.parent().prev().addClass('active focused');
                });

                // on blur check field and remove class if needed
                $this.blur(function () {
                    $this.parent().prev().removeClass('focused');
                    if ($this.val() === '' || $this.val() === 'blank') {
                        $this.parent().prev().removeClass('active');
                    }
                });

                // Check input values on postback and add class "active" if value exists
                if (text_value != '') {
                    $this.parent().prev().addClass('active');
                }
            });

            // Automatically remove floatLabel class from select input on load
            $('select', target).parent().prev().removeClass('active');
        },

        mobMenu: function (menuWrapper) {
            var $this = menuWrapper,
                $win = $(window);

            /* toggle main menu nav */
            $('.menu-btn', $this).on('click', function () {
                menuWrapper.toggleClass('opened');
                App.vars.body.toggleClass('opened');
            });

            $('.top-actions > .close', $this).on('click', function () {
                menuWrapper.removeClass('opened');
                App.vars.body.removeClass('opened');
            });

            $win.on('keyup', function (event) {
                if (App.vars.body.hasClass('opened')) {
                    switch (event.keyCode) {
                        case 27: //esc
                            menuWrapper.removeClass('opened');
                            $('body').removeClass('opened');
                            break;
                    }
                }
            });
        },
        is_touch_device: function () {
            return 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        }
    };

$(document).ready(function () {
    App.init();
    App.mobMenu($('.page-header'));
});
